import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un carpòfor d’1,5 a 4,5 cm quan està tancat i de 5 a 9 cm si està obert. En temps humit s’obre adquirint forma d’estrella formant unes lacínies com les del gènere Geastrum. En temps sec es tanca actuant com un higròmetre. Les espores surten per un por apical del carpòfor i són quasi rodones, berrugoses, de color terrós en massa i de 9-11 micres. La massa central de l’aparell esporífer és blanca de jove i després torna marró obscur.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      